var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.detailsInTooltip && _vm.hasDetails)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"d-flex align-center text-break",class:{
              'justify-left': _vm.justifyLeft,
              'justify-center': !_vm.justifyLeft,
              pointer: _vm.clickable,
            },on:{"click":function($event){$event.stopPropagation();_vm.clickable ? _vm.$helpers.goToUser(_vm.user, _vm.pushTransition) : null}}},[_c('v-avatar',{staticClass:"mr-2 align-self-start",attrs:{"left":"","size":_vm.dense ? 32 : 42}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(_vm.user)}})],1),_c('span',[_vm._v(_vm._s(_vm.$helpers.getUserName(_vm.user)))])],1),(!_vm.detailsInTooltip && _vm.hasDetails)?_c('div',{staticClass:"mt-4"},[(_vm.user.default_address && _vm.user.default_address.name)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$trans("company_name"))+":")]),_vm._v(" "+_vm._s(_vm.user.default_address.name)+" ")]):_vm._e(),(_vm.user.default_address && _vm.user.default_address.tax_number)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("tax"))+": "+_vm._s(_vm.user.default_address.tax_number)+" ")]):_vm._e(),(_vm.user.email)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("email"))+": "+_vm._s(_vm.user.email)+" ")]):_vm._e(),(_vm.user.default_phone)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("phone"))+": "+_vm._s(_vm.user.default_phone.e164)+" ")]):_vm._e()]):_vm._e()])]}}],null,false,3840592230)},[_c('div',[(_vm.user.default_address && _vm.user.default_address.name)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$trans("company_name"))+":")]),_vm._v(" "+_vm._s(_vm.user.default_address.name)+" ")]):_vm._e(),(_vm.user.default_address && _vm.user.default_address.tax_number)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("tax"))+": "+_vm._s(_vm.user.default_address.tax_number)+" ")]):_vm._e(),(_vm.user.email)?_c('div',[_vm._v(_vm._s(_vm.$trans("email"))+": "+_vm._s(_vm.user.email))]):_vm._e(),(_vm.user.default_phone)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("phone"))+": "+_vm._s(_vm.user.default_phone.e164)+" ")]):_vm._e()])])],1):_c('div',[_c('div',{staticClass:"d-flex align-center text-break",class:{
        'justify-left': _vm.justifyLeft,
        'justify-center': !_vm.justifyLeft,
        pointer: _vm.clickable,
      },on:{"click":function($event){$event.stopPropagation();_vm.clickable ? _vm.$helpers.goToUser(_vm.user, _vm.pushTransition) : null}}},[_c('v-avatar',{staticClass:"mr-2 align-self-start",attrs:{"left":"","size":_vm.dense ? 32 : 42}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(_vm.user)}})],1),_c('span',[_vm._v(_vm._s(_vm.$helpers.getUserName(_vm.user)))])],1),(!_vm.detailsInTooltip && _vm.hasDetails)?_c('div',{staticClass:"mt-4"},[(_vm.user.default_address && _vm.user.default_address.name)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$trans("company_name"))+":")]),_vm._v(" "+_vm._s(_vm.user.default_address.name)+" ")]):_vm._e(),(_vm.user.default_address && _vm.user.default_address.tax_number)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("tax"))+": "+_vm._s(_vm.user.default_address.tax_number)+" ")]):_vm._e(),(_vm.user.email)?_c('div',[_vm._v(_vm._s(_vm.$trans("email"))+": "+_vm._s(_vm.user.email))]):_vm._e(),(_vm.user.default_phone)?_c('div',[_vm._v(" "+_vm._s(_vm.$trans("phone"))+": "+_vm._s(_vm.user.default_phone.e164)+" ")]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }