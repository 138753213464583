<template>
  <div>
    <div v-if="detailsInTooltip && hasDetails">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <div
              class="d-flex align-center text-break"
              :class="{
                'justify-left': justifyLeft,
                'justify-center': !justifyLeft,
                pointer: clickable,
              }"
              @click.stop="
                clickable ? $helpers.goToUser(user, pushTransition) : null
              "
            >
              <v-avatar
                left
                class="mr-2 align-self-start"
                :size="dense ? 32 : 42"
              >
                <v-img :src="$helpers.getAvatarSrc(user)" />
              </v-avatar>
              <span>{{ $helpers.getUserName(user) }}</span>
            </div>
            <div v-if="!detailsInTooltip && hasDetails" class="mt-4">
              <div v-if="user.default_address && user.default_address.name">
                <span>{{ $trans("company_name") }}:</span>
                {{ user.default_address.name }}
              </div>
              <div
                v-if="user.default_address && user.default_address.tax_number"
              >
                {{ $trans("tax") }}: {{ user.default_address.tax_number }}
              </div>
              <div v-if="user.email">
                {{ $trans("email") }}: {{ user.email }}
              </div>
              <div v-if="user.default_phone">
                {{ $trans("phone") }}: {{ user.default_phone.e164 }}
              </div>
            </div>
          </div>
        </template>
        <div>
          <div v-if="user.default_address && user.default_address.name">
            <span>{{ $trans("company_name") }}:</span>
            {{ user.default_address.name }}
          </div>
          <div v-if="user.default_address && user.default_address.tax_number">
            {{ $trans("tax") }}: {{ user.default_address.tax_number }}
          </div>
          <div v-if="user.email">{{ $trans("email") }}: {{ user.email }}</div>
          <div v-if="user.default_phone">
            {{ $trans("phone") }}: {{ user.default_phone.e164 }}
          </div>
        </div>
      </v-tooltip>
    </div>
    <div v-else>
      <div
        class="d-flex align-center text-break"
        :class="{
          'justify-left': justifyLeft,
          'justify-center': !justifyLeft,
          pointer: clickable,
        }"
        @click.stop="clickable ? $helpers.goToUser(user, pushTransition) : null"
      >
        <v-avatar left class="mr-2 align-self-start" :size="dense ? 32 : 42">
          <v-img :src="$helpers.getAvatarSrc(user)" />
        </v-avatar>
        <span>{{ $helpers.getUserName(user) }}</span>
      </div>
      <div v-if="!detailsInTooltip && hasDetails" class="mt-4">
        <div v-if="user.default_address && user.default_address.name">
          <span>{{ $trans("company_name") }}:</span>
          {{ user.default_address.name }}
        </div>
        <div v-if="user.default_address && user.default_address.tax_number">
          {{ $trans("tax") }}: {{ user.default_address.tax_number }}
        </div>
        <div v-if="user.email">{{ $trans("email") }}: {{ user.email }}</div>
        <div v-if="user.default_phone">
          {{ $trans("phone") }}: {{ user.default_phone.e164 }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    justifyLeft: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    pushTransition: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    detailsInTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasDetails() {
      return (
        this.user &&
        (this.user.email ||
          (this.user.default_phone && this.user.default_phone.e164) ||
          !this.$helpers.addressIsEmpty(this.user.default_address))
      );
    },
  },
};
</script>
